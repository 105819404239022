<template>
  <div class="con_box">
    <div class="swisn" v-for="(item, index) in imageList" :key="index">
      <img :src="item" alt="" />
    </div>
    <div class="btn">
      <van-button type="primary" size="large" @click="accomplish"
        >完成</van-button
      >
    </div>
  </div>
</template>

<script>
import { getContractImage } from "@/request/api";
export default {
  data() {
    return {
      imageList: "",
      contract: "",
    };
  },
  created() {},
  mounted() {
    const contractData = this.$route.query.contractData;

    if (contractData) {
      this.contract = contractData;
      console.log(this.contract);
    }
    this.lookImageUrl();
  },
  //
  methods: {
    lookImageUrl() {
      getContractImage({
        contractId: this.contract.contractId,
      }).then((res) => {
        console.log(res);
        if ((res.code = "200")) {
          this.imageList = res.data.imageList;
        } else {
          this.$notify({
            type: "warning",
            message: "合同图片获取失败，请点击完成",
          });
        }
      });
    },
    accomplish() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="scss" scoped>
.con_box {
  .swisn {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}
</style>